const WebDevelopmentCrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Services",
    slug: "/services",
  },
  {
    id: 1,
    title: "Web Development Services",
    slug: "/services/development",
  },
]

export default WebDevelopmentCrumbs
