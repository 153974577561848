import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { DevelopmentServices, Hero } from "../../containers"
import WebDevelopmentCrumbs from "../../constants/BreadCrumbs/WedDevelopmentCrumbs"
import { BreadCrumb } from "../../components"

const DevelopmentPage = ({ data }) => {
  const image = getImage(
    data.strapiDevelopment.image.localFile.childImageSharp.gatsbyImageData
  )

  //console.log("DevelopmentPage - data, text:", data.strapiDevelopment.text)
  return (
    <section>
      <Hero image={image} title="Web Development" />
      <BreadCrumb crumbs={WebDevelopmentCrumbs} />
      <DevelopmentServices
        text={data.strapiDevelopment.text}
        title={data.strapiDevelopment.title}
      />
    </section>
  )
}

export default DevelopmentPage

export const query = graphql`
  {
    strapiDevelopment {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
      text
    }
  }
`
